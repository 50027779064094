<template>
  <div class="StepEndView">
    <div class="title">
      {{ $t('title') }}
    </div>
    <div class="text">
      {{ step.number ? $t('text', { number: step.number }) : $t('textNoNumber') }}
    </div>

    <div class="spacer" />

    <div class="icon">
      <svgicon icon="steps-3" />
    </div>

    <div class="spacer" />

    <div
      v-if="!allStepsCompleted"
      class="journeyProgress">
      <div class="journeyProgressBody">
        <div>
          {{ $tc('progressText', numberOfSteps - numberOfCompletedSteps, { journeyName: journey.title }) }}
        </div>
      </div>
    </div>
    <div class="buttons">
      <div
        v-if="isLastStep && allStepsCompleted"
        class="button --global-clickable"
        @click="gotoJourneyQuiz()">
        {{ $t('journeyQuiz') }}
      </div>
      <div
        v-else-if="isLastStep && !allStepsCompleted"
        class="button --global-clickable"
        @click="gotoNextIncompletedStep()">
        {{ $t('nextIncompletedStep') }}
      </div>
      <div
        v-else
        class="button --global-clickable"
        @click="gotoNextStep()">
        {{ $t('nextStep') }}
      </div>
    </div>
  </div>
</template>

<translations>
  # title: 'You finished the step!'
  # title_no: 'Du har fullført steget'

  title: 'Congratulations'
  title_no: 'Gratulerer'

  # text: 'Congrats, you completed the step. Keep up the good work'
  # text_no: 'Gratulerer, du er ferdig med steget. Fortsett med den gode innsatsen!'

  text: 'You completed step {number}'
  text_no: 'Du har fullført steg {number}!'

  textNoNumber: 'You completed the step'
  textNoNumber_no: 'Du har fullført steget'

  nextStep: 'Next step'
  nextStep_no: 'Neste steg'

  # nextIncompletedStep: 'Next unfinished step'
  # nextIncompletedStep_no: 'Neste ugjorte steg'
  nextIncompletedStep: 'Next step'
  nextIncompletedStep_no: 'Neste steg'

  journeyQuiz: 'Take Journey Quiz'
  journeyQuiz_no: 'Ta reisequizen'

  progressText: '{count} more step to complete the journey | {count} more steps to complete the journey'
  progressText_no: '{count} steg gjenstår for å fullføre reisen | {count} steg gjenstår for å fullføre reisen'
</translations>

<script>
import last from 'lodash-es/last';

export default {
    props: {
        stepId: {
            type: String,
            required: true
        }
    },
    computed: {
        step() {
            return this.$store.state.moduleCourse.steps[this.stepId];
        },
        steps() {
            return this.journey.stepIds.map(stepId => this.$store.state.moduleCourse.steps[stepId]);
        },
        journey() {
            return this.$store.state.moduleCourse.stepGroups[this.step.stepGroupId];
        },
        isLastStep() {
            return last(this.journey.stepIds) === this.stepId;
        },
        numberOfSteps() {
            return this.steps.length;
        },
        numberOfCompletedSteps() {
            return this.steps.filter(step => step.progress === 1).length;
        },
        allStepsCompleted() {
            return this.steps.every(step => step.progress === 1);
        }
    },
    methods: {
        gotoNextStep() {
            const stepIndex = this.journey.stepIds.indexOf(this.stepId);
            const nextStepId = this.journey.stepIds[stepIndex + 1];
            this.$router.push({
                name: 'StepFlow-LoadingView',
                params: {
                    stepId: nextStepId
                }
            });
        },
        gotoNextIncompletedStep() {
            const firstIncompleteStepId = this.journey.stepIds.find(stepId => {
                const step = this.$store.state.moduleCourse.steps[stepId];
                return step.progress < 1;
            });
            this.$router.push({
                name: 'StepFlow-LoadingView',
                params: {
                    stepId: firstIncompleteStepId
                }
            });
        },
        gotoJourneyQuiz() {
            this.$router.push({
                name: 'StepGroupQuizView',
                params: { courseId: this.journey.courseId, stepGroupId: this.journey.id }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.StepEndView {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: white;
    padding: 2em 2em;
}

.title {
    font-size: 200%;
    font-weight: 600;
    text-align: center;
}
.text {
    line-height: 1.5em;
    text-align: center;
    color: rgba(white, 0.7);
}

.icon {
    $size: 10em;
    width: $size;
    height: $size;
    background-color: $completionColor;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2em auto;

    .svg-icon {
        $size: 50%;
        width: $size;
        height: $size;
        fill: white;
    }
}

.buttons {
    display: flex;
    // flex-direction: column;
    align-items: center;
    margin-top: 2em;
}
.button {
    padding: 0.7em 2em;
    background-color: white;
    border-radius: 2em;
    color: black;
    text-align: center;

    &:not(:first-child) {
        margin-left: 1em;
    }
}

.journeyProgress {
    display: flex;
    align-items: center;
    color: rgba(white, 0.7);
    text-align: center;
    line-height: 1.5em;
}

@include media('<mobile') {
    .spacer {
        flex: 1;
    }
}
</style>
